import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Explore from "./pages/explore";
import DaoPage from "./pages/dao";
import FormPage from "./pages/formPage";
import OpportunitiesPage from "./pages/opportunitiesPage";
import LandingPage from "./pages/landingPage";
import DaoTool from "./pages/daoTool";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
          <Route path="/daotool" exact element={<DaoTool />} />
          <Route path="/discover" exact element={<Explore />} />
          <Route path="/discover/:daoId" exact element={<DaoPage />} />

          <Route path="/xyz-admin/e71" element={<FormPage />} />
          <Route path="/xyz-admin/e71/oppo" element={<OpportunitiesPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
