import React, { useState } from "react";
import styles from "./styles.module.css";
import { Link, useLocation, NavLink } from "react-router-dom";
import dreputeLogo from "../../assets/images/drepute_logo.svg";

const Header = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.superContainer}>
        <div className={styles.container}>
          <div className={styles.headerLeft}>
            <div className={styles.header}>
              <img src={dreputeLogo} alt="logo" />
              <Link to="/">Drepute</Link>
            </div>
            <ul className={styles.navLinks}>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.active : undefined
                  }
                  to="/dao-tool"
                >
                  DAO TOOL
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.active : undefined
                  }
                  to="/discover"
                >
                  DAO DISCOVERY
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.active : undefined
                  }
                  to="/resources"
                >
                  RESOURCES
                </NavLink>
              </li>
            </ul>
          </div>
          <div className={styles.headerRight}>
            {location.pathname === "/" && (
              <div className={styles.socialContainer}>
                <div className={styles.social}>
                  <a href="https://discord.gg/xxdCpXmpGG" target="_blank">
                    DISCORD
                  </a>
                </div>
                <div className={styles.social}>
                  <a href="https://twitter.com/dreputexyz" target="_blank">
                    TWITTER
                  </a>
                </div>
              </div>
            )}
            <div
              className={`${styles.hamburgerIcon} ${isOpen ? styles.open : ""}`}
              onClick={() => setIsOpen((isOpen) => !isOpen)}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div
              className={`${styles.hamburgerContent} ${
                isOpen ? styles.showMenu : ""
              }`}
            >
              <div className={styles.hamburgerContentContainer}>
                <ul className={styles.hamburgerNavLinks}>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? styles.hamburgerActiveLink : undefined
                      }
                      to="/"
                    >
                      home
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? styles.hamburgerActiveLink : undefined
                      }
                      to="/discover"
                    >
                      discovery
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? styles.hamburgerActiveLink : undefined
                      }
                      to="/resources"
                    >
                      resources
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? styles.hamburgerActiveLink : undefined
                      }
                      to="/dao-tool"
                    >
                      dao tool
                    </NavLink>
                  </li>
                </ul>
                <div className={styles.socialLinks}>
                  <div>twitter</div>
                  <div>discord</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
