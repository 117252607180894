import React from "react";
import styles from "./style.module.css";

export default function HeroSection({ selectedFilter }) {
  return (
    <div className={styles.heroSectionContainer}>
      {/* <div className={styles.title}>
        <div className={styles.highlighted}>
          {selectedFilter === "All DAOs" ? "All" : selectedFilter} DAOs
        </div>
        let users from across the globe pool capital & invest in assets
        collectively.
      </div> */}
      <div className={styles.marqueeDiv}>
        <div className={styles.marqueeContentFirst}>
          join the revolution<span>/</span>
          {/* join the revolution<span>/</span> */}
        </div>

        <div className={styles.marqueeContentSecond}>
          join the revolution<span>/</span>
          {/* join the revolution<span>/</span> */}
        </div>
      </div>
    </div>
  );
}
