import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import axios from "axios";
import Select from "react-select";

export default function FormPage() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [opportunities, setOpportunities] = useState([
    {
      title: "",
      link: "",
      engagement_type: null,
    },
  ]);
  const [daos, setDaos] = useState(null);
  const [selectedDao, setSelectedDao] = useState(null);
  const selectOptions = [
    {
      value: 0,
      label: "Full_Time",
    },
    {
      value: 1,
      label: "Part_Time",
    },
    {
      value: 2,
      label: "Contributor",
    },
    {
      value: 3,
      label: "Bounty",
    },
    {
      value: 4,
      label: "Freelance",
    },
  ];

  useEffect(() => {
    const fetchDaos = async () => {
      const resp = await axios.get("https://staging.api.drepute.xyz:8000/dao");
      if (resp.status === 200) {
        const mappedDaos = resp.data.data.daos.map((dao) => ({
          value: dao.user_name,
          label: dao.user_name,
        }));
        setDaos(mappedDaos);
      }
    };
    fetchDaos();
  }, []);

  const sendForm = async () => {
    opportunities.forEach((opportunity, index) => {
      if (!opportunity.title) {
        alert(`opportunity ${index + 1} does not have a title`);
        return;
      } else if (!opportunity.link) {
        alert(`opportunity ${index + 1} does not have a link`);
        return;
      } else if (!opportunity.engagement_type) {
        alert(`opportunity ${index + 1} does not have a type`);
        return;
      }
    });
    const opportunitiesMapped = opportunities.map((opportunity) => ({
      ...opportunity,
      engagement_type: opportunity.engagement_type.label.toUpperCase(),
    }));
    const formData = {
      dao_username: selectedDao.value,
      opportunities: opportunitiesMapped,
    };
    const response = await axios.post(
      "https://staging.api.drepute.xyz:8000/dao/opportunity/r3pute",
      formData
    );
    if (response.status === 200) {
      setFormSubmitted(true);
      setOpportunities([
        {
          title: "",
          engagement_type: null,
          link: "",
        },
      ]);
    }
  };

  const addOpportunity = () => {
    const newOpportunity = {
      title: "",
      engagement_type: null,
      link: "",
    };
    setOpportunities((opportunities) => [...opportunities, newOpportunity]);
  };

  const handleChange = (e, index, key) => {
    const opportunitiesMapped = opportunities.map((opportunity) => ({
      ...opportunity,
    }));
    opportunitiesMapped[index][key] =
      key === "engagement_type" ? e : e.target.value;
    setOpportunities(opportunitiesMapped);
  };

  const removeOpportunity = (index) => {
    const opportunitiesFiltered = opportunities.filter(
      (_, idx) => idx !== index
    );
    setOpportunities(opportunitiesFiltered);
  };

  if (!daos) {
    return <div>Daos not found</div>;
  }

  return (
    <div className={styles.FormPage}>
      {formSubmitted ? (
        <div>
          <h1>Form Submitted Successfully</h1>
          <button onClick={() => setFormSubmitted(false)}>Fill Another</button>
        </div>
      ) : (
        <>
          <h1>DAO Opportunities Form</h1>

          <div className={styles.formInputContainer}>
            <label>Type</label>
            <Select
              defaultValue={selectedDao}
              onChange={setSelectedDao}
              options={daos}
            />
          </div>

          {opportunities.map((opportunity, index) => (
            <div key={index}>
              <div className={styles.opportunityName}>
                <h3>Opportunity {index + 1}</h3>
                <button onClick={() => removeOpportunity(index)}>Delete</button>
              </div>
              <div className={styles.formInputContainer}>
                <label>Title</label>
                <input
                  type="text"
                  value={opportunity.title}
                  onChange={(e) => handleChange(e, index, "title")}
                  placeholder="Enter title"
                />
              </div>
              <div className={styles.formInputContainer}>
                <label>Link</label>
                <input
                  type="text"
                  value={opportunity.link}
                  onChange={(e) => handleChange(e, index, "link")}
                  placeholder="Enter Link"
                />
              </div>

              <div className={styles.formInputContainer}>
                <label>Type</label>
                <Select
                  defaultValue={opportunity.engagement_type}
                  onChange={(e) => handleChange(e, index, "engagement_type")}
                  options={selectOptions}
                />
              </div>
            </div>
          ))}
          <div>
            <button onClick={addOpportunity}>Add Another</button>
          </div>
          <div className={styles.formButtons}>
            <button onClick={() => sendForm()}>Send</button>
          </div>
        </>
      )}
    </div>
  );
}
