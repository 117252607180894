import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import styles from "./style.module.css";
// import discordImg from "../../assets/icons/discord.png";
import twitterImg from "../../assets/icons/twitter.png";
import websiteImg from "../../assets/icons/website.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import linkArrowImg from "../../assets/icons/arrow-up-right.svg";
import Footer from "../../component/Footer";
import Header from "../../component/Header";

export default function DaoPage() {
  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const readRef = useRef(null);
  const howToJoinRef = useRef(null);
  const [selectedDao, setSelectedDao] = useState(null);
  // const [selectedNav, setSelectedNav] = useState("Overview");
  const [readMoreOpen, setReadMoreOpen] = useState(false);

  const { daoId } = useParams();

  useEffect(() => {
    const fetchDaos = async () => {
      const resp = await axios.get(
        `https://staging.api.drepute.xyz:8000/dao/${daoId}`
      );
      if (resp.status === 200) {
        const daos = resp.data.data;
        setSelectedDao(daos);
      }
    };
    fetchDaos();
  }, [daoId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onScroll = () => {
    if (window.scrollY > 125) {
      headerRef.current?.classList.add(styles.animate);
      bodyRef.current?.classList.add(styles.marginAdded);
    } else {
      headerRef.current?.classList.remove(styles.animate);
      bodyRef.current?.classList.remove(styles.marginAdded);
    }
  };
  useLayoutEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // const scrollIntoView = () => {
  //   howToJoinRef.current.scrollIntoView({
  //     behavior: "smooth",
  //     block: "end",
  //     inline: "nearest",
  //   });
  // };

  // const readMore = () => {
  //   // console.log(readRef.current); need to be removed
  //   readRef.current.classList.toggle(styles.readMoreContent);
  // };

  if (!selectedDao) {
    return <div>No DAO with this name found</div>;
  }

  return (
    <>
      <Header />
      <div className={styles.superContainer}>
        <div className={styles.container}>
          <div className={styles.daoHeader} ref={headerRef}>
            <div className={styles.daoCoverImage}>
              <img
                src={selectedDao.banner}
                alt=""
                className={styles.coverImage}
              />
            </div>
            <div className={styles.daoHeaderContent}>
              <img src={selectedDao.pfp} alt="" />
              <div className={styles.daoHeaderInfo}>
                <div className={styles.row}>
                  <div className={styles.name}>{selectedDao?.name}</div>
                  <div className={styles.date}>Launched Mar 2021</div>
                </div>
                <div className={`${styles.row} ${styles.taglineRow}`}>
                  <div className={styles.tagline}>{selectedDao?.tag_line}</div>
                  <div className={styles.cardSocials}>
                    {/* <div className={styles.socialCard}>
                      <img src={discordImg} alt="" />
                      237k
                    </div> */}
                    <div className={styles.socialCard}>
                      <img src={twitterImg} alt="" />
                      23k
                    </div>
                    <div
                      className={`${styles.socialCard} ${styles.RightSocialCard}`}
                    >
                      <img src={websiteImg} alt="" />
                      Website
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={styles.daoNavbar}>
              <div
                className={`${styles.link} ${
                  selectedNav === "Overview" ? styles.active : ""
                }`}
                onClick={() => setSelectedNav("Overview")}
              >
                Overview
              </div>
              <div
                className={`${styles.link} ${
                  selectedNav === "Articles" ? styles.active : ""
                }`}
                onClick={() => setSelectedNav("Articles")}
              >
                Articles
              </div>
              <div
                className={`${styles.link} ${
                  selectedNav === "HowToJoin" ? styles.active : ""
                }`}
                onClick={() => {
                  scrollIntoView();
                  setSelectedNav("HowToJoin");
                }}
              >
                How to join
              </div>
              <div
                className={`${styles.link} ${
                  selectedNav === "SocialFeed" ? styles.active : ""
                }`}
                onClick={() => setSelectedNav("SocialFeed")}
              >
                Social feed
              </div>
              <div
                className={`${styles.link} ${
                  selectedNav === "Proposals" ? styles.active : ""
                }`}
                onClick={() => setSelectedNav("Proposals")}
              >
                Proposals
              </div>
            </div> */}
          </div>
          <div className={styles.pageBody} ref={bodyRef}>
            {/* <div className={styles.bodyContent}> */}
            <div className={styles.bodyLeft}>
              <div className={`${styles.bodyCard} ${styles.aboutUs}`}>
                <div className={styles.title}>About us</div>
                <div
                  className={`${styles.contentBody} ${
                    readMoreOpen ? styles.readMoreContent : ""
                  }`}
                  ref={readRef}
                >
                  The protocol differentiates from the likes of Bitcoin or
                  Ethereum, by relying on community members to guide the
                  distribution process by creating and staking on Honey
                  distribution proposals. The protocol differentiates from the
                  likes of Bitcoin or Ethereum, by relying on community members
                  to guide the distribution process by creating and staking on
                  Honey distribution proposals. The protocol differentiates from
                  the likes of Bitcoin or Ethereum, by relying on community
                  members to guide the distribution process by creating and
                  staking on Honey distribution proposals.
                </div>
                <div
                  className={styles.readButton}
                  onClick={() => setReadMoreOpen((open) => !open)}
                >
                  {!readMoreOpen ? "Read more" : "Read less"}
                </div>
              </div>
              <div className={styles.bodyCard} id="join" ref={howToJoinRef}>
                <div className={styles.title}>How to join</div>
                <div className={styles.contentBody}>
                  <li>
                    Go to their discord (#looking-to-contribute) and drop your
                    info.
                  </li>
                  <li>
                    In order to get voting power you have to have any form of
                    Badger (bBadger or LP, staked or in your wallet) before the
                    snapshot was published. If the total amount you own is below
                    1 it will still show 0 as voting power when you try to vote
                    but your vote will count for the amount that you have.
                  </li>
                  <li>
                    If you have voting power, you can vote on their Snapshot.
                  </li>
                </div>
              </div>
            </div>
            <div className={styles.sideBar}>
              <div className={styles.sideBarTitle}>Opportunities</div>
              <div className={styles.opportunitiesContainer}>
                {selectedDao.opportunities.map((opportunity, index) => (
                  <div
                    className={`${styles.opportunity} ${
                      index === selectedDao.opportunities.length - 1
                        ? ""
                        : styles.opportunityBorder
                    } `}
                    key={index}
                  >
                    <div className={styles.opportunityLeft}>
                      <div className={styles.opportunityTitle}>
                        {opportunity.title}
                      </div>
                      <div className={styles.opportunityEngagement}>
                        {opportunity.engagement.name}
                      </div>
                    </div>
                    <div className={styles.opportunityRight}>
                      <a
                        href={opportunity.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Apply <img src={linkArrowImg} alt="" />
                      </a>
                    </div>
                  </div>
                ))}
                {!selectedDao.opportunities.length && (
                  <div className={styles.opportunityEmptyScreen}>
                    {selectedDao?.name} currently has no openings.
                    <span>Please check back later.</span>
                  </div>
                )}
              </div>
            </div>
            {/* </div> */}
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
}
