import React, { useState } from "react";
import styles from "./styles.module.css";
import { IoLogoTwitter } from "react-icons/io";
import { FaDiscord } from "react-icons/fa";
import axios from "axios";

function FooterComp() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const subscribeToNewsletter = async () => {
    if (validateEmail(email)) {
      setError("");
      const resp = await axios.post(`https://staging.api.drepute.xyz:8000/newsletter`, {
        email_id: email,
      });
      if (resp.status === 200) {
        setEmail("");
      } else {
        setError("There was some error. Please try again");
      }
    } else {
      setError("Please add a valid email address");
    }
  };
  return (
    <div className={styles.superContainer}>
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          Subscribe to <br />
          newsletter and don’t
          <br />
          miss out on anything
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={styles.inputContainer}>
            <input
              placeholder="Drop your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={styles.input}
            />
          </div>
          <div className={styles.btnContainer} onClick={subscribeToNewsletter}>
            Subscribe
          </div>
        </div>
        <div className={styles.emailError}>{error}</div>
      </div>

      <div className={styles.footer}>
        <div className={styles.community}>Join the Community</div>

        <div>
        <FaDiscord  size={24} color="#5865F2" />
          <IoLogoTwitter size={24} color="#1DA1F2" className={styles.discord} />
        </div>
      </div>
    </div>
    </div>
  );
}

export default FooterComp;
