import React, { useRef } from "react";
import styles from "./style.module.css";
import discordImg from "../../assets/icons/discord.png";
import twitterImg from "../../assets/icons/twitter.png";
import websiteImg from "../../assets/icons/website.png";
import { useNavigate } from "react-router-dom";

export default function DaoCardAnimated({ daoDetails, selectedFilter }) {
  const navigate = useNavigate();
  const cardRef = useRef(null);

  // useEffect(() => {
  //   console.log(
  //     cardRef.current,
  //     cardRef.current.style.height,
  //     cardRef.current.style.width,
  //     cardRef.current.getBoundingClientRect().width
  //   );
  //   cardRef.current.style.height = `${
  //     0.95 * cardRef.current.getBoundingClientRect().width
  //   }px`;
  // }, []);

  const openDaoPage = () => {
    navigate(`/discover/${daoDetails.user_name}`);
  };
  return (
    <div className={styles.container} onClick={openDaoPage} ref={cardRef}>
      <div className={styles.badgesContainer}>
        {selectedFilter === "All" ? (
          <div className={`${styles.badge} ${styles.daoTypeBadge}`}>
            {daoDetails.dao_types[0]
              ? daoDetails.dao_types[0].name.toLowerCase()
              : "Social"}
          </div>
        ) : (
          <div></div>
        )}
        {daoDetails.opportunities_count ? (
          <div className={styles.badge}>
            {daoDetails.opportunities_count} open{" "}
            {daoDetails.opportunities_count > 1 ? "positions" : "position"}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <img src={daoDetails.banner} alt="" loading="lazy" />
      <div className={styles.avatarImage} alt="">
        <img src={daoDetails.pfp} alt="" loading="lazy" />
      </div>
      <div className={styles.cardGradient}></div>

      <div className={styles.cardContent}>
        <div className={styles.cardTitleRow}>
          <div className={styles.cardTitle}>
            {daoDetails.name}
            {/* <img src={blueTickImg} alt="" /> */}
          </div>
          <div className={styles.cardDate}>
            Launched Mar 2022
            {/* {format(daoDetails.launch_date, "MMM yyyy")} */}
          </div>
        </div>
        <div className={styles.cardSubtitle}>
          {/* Where communities build & fund the open web – $GTC */}
          {daoDetails.tag_line}
        </div>
        <div className={styles.cardSocials}>
          <div className={styles.socialCard}>
            <div>
              <img src={discordImg} alt="" />
            </div>
            <div className={styles.socialCardNumber}>237k</div>
          </div>
          <div className={styles.socialCard}>
            <div>
              <img src={twitterImg} alt="" />
            </div>
            <div className={styles.socialCardNumber}>23k</div>
          </div>
          <div className={styles.socialCard}>
            <div>
              <img src={websiteImg} alt="" />
            </div>
            <div className={styles.socialCardNumber}>Website</div>
          </div>
        </div>
      </div>
    </div>
  );
}
