import React, { useState, useEffect } from "react";
import DaoCardAnimated from "../../component/DaoCardAnimated";
import styles from "./styles.module.css";
import HeroSection from "../../component/HeroSection";
import axios from "axios";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import DaoFiltersImg from "../../assets/icons/dao_filters.svg";
import ChevronRight from "../../assets/icons/chevron-right.svg";
import TickMark from "../../assets/icons/tick_mark.svg";
import CloseIcon from "../../assets/icons/close.svg";

function Explore() {
  const [daos, setDaos] = useState(null);
  const [DAOFilters, setDAOFilters] = useState([
    "All DAOs",
    "Service",
    "Grant",
    "Media",
    "Social",
    "Investment",
  ]);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [filteredDaos, setFilteredDaos] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  useEffect(() => {
    const fetchDaos = async () => {
      const resp = await axios.get("https://staging.api.drepute.xyz:8000/dao");
      if (resp.status === 200) {
        const filters = resp.data.data.daos.reduce((acc, dao) => {
          return [...acc, ...dao.dao_types];
        }, []);
        const filtersMapped = filters.map((filter) => filter.name);
        const uniqueItems = [...new Set(filtersMapped)];
        setDAOFilters(["All", ...uniqueItems]);
        setDaos(resp.data.data.daos);
      }
    };
    fetchDaos();
  }, []);

  useEffect(() => {
    if (selectedFilter === "All") {
      setFilteredDaos(daos);
    } else {
      setFilteredDaos(
        daos?.filter((dao) =>
          dao.dao_types
            .map((type) => type.name.toLowerCase())
            .includes(selectedFilter.toLowerCase())
        )
      );
    }
  }, [selectedFilter, daos]);

  return (
    <div>
      <Header />
      <div className={styles.heroSectionContainer}>
        <HeroSection selectedFilter={selectedFilter} />
      </div>
      {daos && daos.length > 0 ? (
        <>
          <div className={styles.superContainer}>
            <div className={styles.daoFiltersMobileWrapper}>
              <div className={styles.daoFiltersMobile}>
                {selectedFilter}
                <div onClick={() => setShowFilters(true)}>
                  <img src={DaoFiltersImg} alt="filter" />
                </div>
              </div>
            </div>
            <div
              className={`${styles.daoFiltersMobileBottomBarWrapper} ${
                showFilters ? styles.filtersShown : ""
              }`}
              onClick={() => setShowFilters(false)}
            >
              <div
                className={styles.daoFiltersMobileBottomBar}
                onClick={(e) => e.stopPropagation()}
              >
                <div className={styles.closeIconWrapper}>
                  <img
                    src={CloseIcon}
                    alt="close"
                    onClick={() => setShowFilters(false)}
                  />
                </div>
                {DAOFilters.map((filter, index) => (
                  <div
                    className={styles.daoMobileFilterWrapper}
                    onClick={() => {
                      setSelectedFilter(filter);
                      setShowFilters(false);
                    }}
                    key={index}
                  >
                    <div className={styles.daoMobileFilter}>
                      {filter.toLowerCase()}
                    </div>
                    {filter === selectedFilter ? (
                      <img
                        src={TickMark}
                        alt="icon"
                        className={styles.checkMark}
                      />
                    ) : (
                      <img src={ChevronRight} alt="icon" />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.daoFiltersContainer}>
                {DAOFilters.map((filter, index) => (
                  <div
                    className={`${styles.daoFilter} ${styles[filter]} ${
                      filter === selectedFilter ? styles.active : ""
                    }`}
                    onClick={() => setSelectedFilter(filter)}
                    key={index}
                  >
                    {filter.toLowerCase()}
                  </div>
                ))}
              </div>
              <div className={styles.daoCardsContainer}>
                {filteredDaos &&
                  filteredDaos.map((dao) => (
                    <DaoCardAnimated
                      key={dao.user_name}
                      daoDetails={dao}
                      selectedFilter={selectedFilter}
                    />
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.superContainer}>
          <div className={styles.container}>
            <div className={styles.daoCardsContainer}>
              <div className={styles.loadingCard}>
                <div className={styles.loadingBottomBar}>
                  <div className={styles.loadingName}></div>
                  <div className={styles.loadingTagline}></div>
                </div>
              </div>
              <div className={styles.loadingCard}>
                <div className={styles.loadingBottomBar}>
                  <div className={styles.loadingName}></div>
                  <div className={styles.loadingTagline}></div>
                </div>
              </div>
              <div className={styles.loadingCard}>
                <div className={styles.loadingBottomBar}>
                  <div className={styles.loadingName}></div>
                  <div className={styles.loadingTagline}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Explore;
