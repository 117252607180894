import React, { useLayoutEffect, useRef, useState } from "react";
import LandingAnimation from "../../assets/videos/LandingAnimation.mp4";
// import dreputeLandingVideo from "../../assets/videos/drepute_landing_video.mp4";
import styles from "./style.module.css";
import Header from "../../component/Header";
// import SkeletonHand from "../../assets/images/SkeletonHand.png";
import WhiteChains from "../../assets/images/WhiteChains.png";
import Circle from "../../assets/icons/Ellipse.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import ScrollMagic from "scrollmagic";
import twitterIcon from "../../assets/icons/twitterIcon.svg";
import discordIcon from "../../assets/icons/discordIcon.svg";
import video1 from "../../assets/images/video1.jpg";
import video2 from "../../assets/images/video2.jpg";
import video3 from "../../assets/images/video3.jpg";
import video4 from "../../assets/images/video4.jpg";
import HandRotation from "../../assets/videos/Hand_rotation.webm";
// import htmlContent from "../../component/index.html";

export default function LandingPage() {
  gsap.registerPlugin(ScrollTrigger);

  const boundRef = useRef(null);
  const videoRef = useRef(null);
  const chainVideoRef = useRef(null);
  const landingTitleRef = useRef(null);
  const missionLineRef = useRef(null);
  const chainImageRef = useRef(null);
  const whiteChainWrappperRef = useRef(null);
  const scrollBoundVidRef = useRef(null);
  const scrollTextRef1 = useRef(null);
  const scrollTextRefContainer1 = useRef(null);
  const scrollTextRef2 = useRef(null);
  const scrollTextRefContainer2 = useRef(null);
  const scrollTextRef3 = useRef(null);
  const scrollTextRefContainer3 = useRef(null);
  const scrollTextRef4 = useRef(null);
  const scrollTextRefContainer4 = useRef(null);
  const scrollContentVidRef = useRef(null);
  const handRotationRef = useRef(null);
  // const scrollerRef1 = useRef(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [email, setEmail] = useState("");
  // const [currentFrame, setCurrentFrame] = useState("001");
  // const boundY = boundRef.current.getBoundingClientRect().top;

  // const registerVideo = () => {
  //   const bound = boundRef.current;
  //   const video = videoRef.current;
  //   const scrollVideo = () => {
  //     // console.log("in scroll video");
  //     if (video.duration) {
  //       // console.log("in scroll video inside if", );
  //       const distanceFromTop =
  //         window.scrollY + bound.getBoundingClientRect().top;
  //       const rawPercentScrolled =
  //         (window.innerHeight - bound.getBoundingClientRect().top - 500) /
  //         (bound.scrollHeight - window.innerHeight);
  //       const percentScrolled = Math.min(Math.max(rawPercentScrolled, 0), 1);
  //       // console.log(
  //       //   "in scroll video inside if values",
  //       //   // boundY,
  //       //   window.scrollY,
  //       //   bound.getBoundingClientRect().top,
  //       //   bound.scrollHeight,
  //       //   window.innerHeight,
  //       //   distanceFromTop,
  //       //   rawPercentScrolled,
  //       //   percentScrolled
  //       // );
  //       video.currentTime = video.duration * percentScrolled;
  //     }
  //     requestAnimationFrame(scrollVideo);
  //   };
  //   requestAnimationFrame(scrollVideo);
  // };

  // useEffect(() => {
  //   registerVideo();
  // }, []);

  useLayoutEffect(() => {
    // gsap.to(chainVideoRef.current, {
    //   y: -30,
    //   scrollTrigger: {
    //     trigger: chainVideoRef.current,
    //     scrub: true,
    //     start: "top bottom",
    //     end: "bottom center",
    //   },
    // });
    // if (window.innerWidth > 768) {
    //   boundRef.current.style.height = window.innerHeight * 3.5;
    // }
    gsap.fromTo(
      missionLineRef.current,
      {
        width: 0,
      },
      {
        width: "100%",
        scrollTrigger: {
          trigger: missionLineRef.current,
          scrub: true,
        },
      }
    );
    // gsap.to({
    //   y:,
    //   scrollTrigger: {
    //     trigger: handRotationRef.current,
    //     start:`top ${window.innerHeight * 0.4}`,
    //     scrub: true,
    //     onUpdate: () => {

    //     }
    //   }
    // })
    ScrollTrigger.matchMedia({
      "(max-width: 768px)": () => {
        gsap.fromTo(
          chainImageRef.current,
          {
            y: -100,
            scale: 1.5,
          },
          {
            y: 0,
            scrollTrigger: {
              trigger: whiteChainWrappperRef.current,
              scrub: true,
            },
          }
        );
        gsap.to(landingTitleRef.current, {
          y: landingTitleRef.current.getBoundingClientRect().height * -1,
          scrollTrigger: {
            trigger: chainVideoRef.current,
            scrub: true,
            start: `top ${window.innerHeight * 0.8}`,
            end: "bottom top",
          },
        });
      },
      "(min-width: 768px)": () => {
        gsap.to(landingTitleRef.current, {
          y: landingTitleRef.current.getBoundingClientRect().height * -1,
          scrollTrigger: {
            trigger: chainVideoRef.current,
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        });
        gsap.fromTo(
          chainImageRef.current,
          {
            y: 200,
          },
          {
            y: 0,
            scrollTrigger: {
              trigger: whiteChainWrappperRef.current,
              scrub: true,
            },
          }
        );
        // gsap.to(scrollTextRef1.current, {
        //   top: "35vh",
        //   scrollTrigger: {
        //     trigger: scrollTextRefContainer1.current,
        //     start: `top ${
        //       window.innerHeight * 0.15 +
        //       videoRef.current.getBoundingClientRect().height / 2
        //     }`,
        //     end: `bottom ${window.innerHeight * 0.15}`,
        //     // markers: true,
        //     scrub: true,
        //     // pin: true,
        //     // onEnter: () => {
        //     //   scrollTextRef1.current.classList.add(styles.animateTextBottom);
        //     // },
        //   },
        // });
        gsap.fromTo(
          scrollTextRef1.current,
          {
            opacity: 1,
          },
          {
            opacity: 0,
            scrollTrigger: {
              trigger: scrollTextRef1.current,
              start: `top ${window.innerHeight * 0.2}`,
              end: `top top`,
              // markers: true,
              scrub: true,
            },
          }
        );
        gsap.fromTo(
          scrollTextRef1.current,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            scrollTrigger: {
              trigger: scrollTextRef1.current,
              start: `top ${window.innerHeight * 0.8}`,
              end: `top ${window.innerHeight * 0.5}`,
              // markers: true,
              scrub: true,
            },
          }
        );
        gsap.fromTo(
          scrollTextRef2.current,
          {
            opacity: 1,
          },
          {
            opacity: 0,
            scrollTrigger: {
              trigger: scrollTextRef2.current,
              start: `top ${window.innerHeight * 0.2}`,
              end: `top top`,
              // markers: true,
              scrub: true,
            },
          }
        );
        gsap.fromTo(
          scrollTextRef2.current,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            scrollTrigger: {
              trigger: scrollTextRef2.current,
              start: `top ${window.innerHeight * 0.8}`,
              end: `top ${window.innerHeight * 0.5}`,
              // markers: true,
              scrub: true,
            },
          }
        );
        gsap.fromTo(
          scrollTextRef3.current,
          {
            opacity: 1,
          },
          {
            opacity: 0,
            scrollTrigger: {
              trigger: scrollTextRef3.current,
              start: `top ${window.innerHeight * 0.2}`,
              end: `top top`,
              // markers: true,
              scrub: true,
            },
          }
        );
        gsap.fromTo(
          scrollTextRef3.current,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            scrollTrigger: {
              trigger: scrollTextRef3.current,
              start: `top ${window.innerHeight * 0.8}`,
              end: `top ${window.innerHeight * 0.5}`,
              // markers: true,
              scrub: true,
            },
          }
        );

        gsap.fromTo(
          scrollTextRef4.current,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            scrollTrigger: {
              trigger: scrollTextRef4.current,
              start: `top ${window.innerHeight * 0.8}`,
              end: `top ${window.innerHeight * 0.5}`,
              // markers: true,
              scrub: true,
            },
          }
        );
        // gsap.fromTo(
        //   scrollTextRef4.current,
        //   {
        //     opacity: 1,
        //   },
        //   {
        //     opacity: 0,
        //     scrollTrigger: {
        //       trigger: scrollTextRef4.current,
        //       start: `top ${window.innerHeight * 0.3}`,
        //       end: `top top`,
        //       markers: true,
        //       scrub: true,
        //     },
        //   }
        // );
        // gsap.to(scrollTextRef2.current, {
        //   // top: "35vh",
        //   scrollTrigger: {
        //     trigger: scrollTextRefContainer2.current,
        //     start: `${
        //       scrollTextRefContainer2.current.getBoundingClientRect().height -
        //       scrollTextRef2.current.getBoundingClientRect().height
        //     } ${window.innerHeight * 0.3}`,
        //     end: `${
        //       scrollTextRefContainer2.current.getBoundingClientRect().height -
        //       scrollTextRef2.current.getBoundingClientRect().height
        //     } ${window.innerHeight * 0.3}`,
        //     // markers: true,
        //     scrub: true,
        //     // pin: true,
        //     onEnter: () => {
        //       scrollTextRef2.current.style.opacity = 0;
        //       scrollTextRef3.current.style.opacity = 1;
        //     },
        //     onEnterBack: () => {
        //       scrollTextRef2.current.style.opacity = 1;
        //       scrollTextRef3.current.style.opacity = 0;
        //     },
        //   },
        // });
        // gsap.to(scrollTextRef3.current, {
        //   // top: "35vh",
        //   scrollTrigger: {
        //     trigger: scrollTextRefContainer3.current,
        //     start: `${
        //       scrollTextRefContainer3.current.getBoundingClientRect().height -
        //       scrollTextRef3.current.getBoundingClientRect().height
        //     } ${window.innerHeight * 0.3}`,
        //     end: `${
        //       scrollTextRefContainer3.current.getBoundingClientRect().height -
        //       scrollTextRef3.current.getBoundingClientRect().height
        //     } ${window.innerHeight * 0.3}`,
        //     // markers: true,
        //     scrub: true,
        //     // pin: true,
        //     onEnter: () => {
        //       scrollTextRef3.current.style.opacity = 0;
        //       scrollTextRef4.current.style.opacity = 1;
        //     },
        //     onEnterBack: () => {
        //       scrollTextRef3.current.style.opacity = 1;
        //       scrollTextRef4.current.style.opacity = 0;
        //     },
        //   },
        // });
        // // gsap.to(scrollTextRef4.current, {
        // //   // top: "35vh",
        // //   scrollTrigger: {
        // //     trigger: scrollTextRefContainer4.current,
        // //     start: `${
        // //       scrollTextRefContainer4.current.getBoundingClientRect().height -
        // //       scrollTextRef4.current.getBoundingClientRect().height
        // //     } ${window.innerHeight * 0.4}`,
        // //     end: `${
        // //       scrollTextRefContainer4.current.getBoundingClientRect().height -
        // //       scrollTextRef4.current.getBoundingClientRect().height
        // //     } ${window.innerHeight * 0.4}`,
        // //     markers: true,
        // //     scrub: true,
        // //     // pin: true,
        // //     onEnter: () => {
        // //       scrollTextRef4.current.style.opacity = 0;
        // //     },
        // //     onEnterBack: () => {
        // //       scrollTextRef4.current.style.opacity = 1;
        // //     },
        // //   },
        // // });

        // gsap.to(scrollTextRef2.current, {
        //   top: "35vh",
        //   scrollTrigger: {
        //     trigger: scrollTextRefContainer2.current,
        //     start: `top ${window.innerHeight * 0.8}`,
        //     end: `bottom ${window.innerHeight * 0.3}`,
        //     // markers: true,
        //     scrub: true,
        //     // onEnter: () => {
        //     //   scrollTextRef1.current.classList.add(styles.animateTextBottom);
        //     // },
        //   },
        // });
        // gsap.to(scrollTextRef3.current, {
        //   top: "35vh",
        //   scrollTrigger: {
        //     trigger: scrollTextRefContainer3.current,
        //     start: `top ${window.innerHeight * 0.8}`,
        //     end: `bottom ${window.innerHeight * 0.3}`,
        //     // markers: true,
        //     scrub: true,
        //     // onEnter: () => {
        //     //   scrollTextRef1.current.classList.add(styles.animateTextBottom);
        //     // },
        //   },
        // });
        // gsap.to(scrollTextRef4.current, {
        //   top: "35vh",
        //   scrollTrigger: {
        //     trigger: scrollTextRefContainer4.current,
        //     start: `top ${window.innerHeight * 0.8}`,
        //     end: `bottom ${window.innerHeight * 0.3}`,
        //     // markers: true,
        //     scrub: true,
        //     // onEnter: () => {
        //     //   scrollTextRef1.current.classList.add(styles.animateTextBottom);
        //     // },
        //   },
        // });
      },
    });

    // gsap.to(scrollTextRef1.current, {
    //   scrollTrigger: {
    //     trigger: scrollTextRef2.current,
    //     start: `bottom ${window.innerHeight * 0.8}`,
    //     onEnter: () => {
    //       scrollTextRef1.current.style.opacity = 0;
    //     },
    //     onLeave: () => {
    //       scrollTextRef1.current.style.opacity = 1;
    //     },
    //     onEnterBack: () => {
    //       scrollTextRef1.current.style.opacity = 0;
    //     },
    //   },
    // });
    // gsap.to(scrollTextRef2.current, {
    //   scrollTrigger: {
    //     trigger: scrollTextRef2.current,
    //     start: `bottom ${window.innerHeight * 0.8}`,
    //     end: `bottom top`,
    //     markers: true,
    //     onEnter: () => {
    //       scrollTextRef2.current.style.opacity = 1;
    //     },
    //     onLeave: () => {
    //       scrollTextRef2.current.style.opacity = 0;
    //     },
    //     onEnterBack: () => {
    //       scrollTextRef2.current.style.opacity = 1;
    //     },
    //   },
    // });
    // gsap.to(videoRef.current, {
    //   opacity: 1,
    //   scrollTrigger: {
    //     scrub: 0.5,
    //     trigger: boundRef.current,
    //     start: `top ${window.innerHeight * 0.8}`,
    //     end: `bottom ${window.innerHeight * 0.8}`,
    //     markers: true,
    //     onUpdate: (op) => {
    //       console.log(
    //         "op",
    //         op.getVelocity(),
    //         op,
    //         (op.progress * 4.5).toFixed(2)
    //       );
    //       videoRef.current.currentTime = (op.progress * 4.5).toFixed(2);
    //       // setTimeout(() => {
    //       //   videoRef.current.currentTime = (op.progress * 4.55).toFixed(2);
    //       // }, 10);
    //       // setTimeout(() => {
    //       //   videoRef.current.currentTime = (op.progress * 4.6).toFixed(2);
    //       // }, 20);
    //     },
    //   },
    // });

    // const controller = new ScrollMagic.Controller();

    // //Scenes
    // let scene = new ScrollMagic.Scene({
    //   duration: 7000,
    //   triggerElement: boundRef.current,
    //   triggerHook: 0,
    //   // offset: 2000,
    // })
    //   // .addIndicators()
    //   // .setPin(boundRef.current)
    //   .addTo(controller);

    // //Video Animation
    // let accelamount = 0.1;
    // let scrollpos = 0;
    // let delay = 0;

    // scene.on("update", (e) => {
    //   // console.log(
    //   //   "e is",
    //   //   e.scrollPos,
    //   //   e.startPos,
    //   //   window.innerHeight - boundRef.current.getBoundingClientRect().top
    //   // );
    //   // const rawPercentScrolled = (e.scrollPos - e.startPos) / 1000;
    //   // scrollpos = Math.min(Math.max(rawPercentScrolled, 0), 1) * 5000;
    //   if (boundRef.current.getBoundingClientRect().top < window.innerHeight) {
    //     scrollpos =
    //       (window.innerHeight -
    //         boundRef.current.getBoundingClientRect().top -
    //         300) /
    //       1000;

    //     // console.log(
    //     //   "updating scrollpos to",
    //     //   scrollpos,
    //     //   delay + (scrollpos - delay) * accelamount
    //     // );
    //   }
    // });

    // // setInterval(() => {
    // //   const delayOld = delay;
    // //   delay += (scrollpos - delay) * accelamount;
    // //   // console.log(scrollpos, delay.toFixed(2));
    // //   // console.log("callinf setInterval", scrollpos, delay, delayOld);
    // //   if (delay.toFixed(4) !== delayOld.toFixed(4)) {
    // //     // console.log("setting video");
    // //     videoRef.current.currentTime = delay.toFixed(4);
    // //   }
    // // }, 33.3);
    // const seekVideo = () => {
    //   const delayOld = delay;
    //   delay += (scrollpos - delay) * accelamount;
    //   // console.log(scrollpos, delay.toFixed(2));
    //   // console.log("callinf setInterval", scrollpos, delay, delayOld);
    //   if (delay.toFixed(4) !== delayOld.toFixed(4)) {
    //     // console.log("setting video");
    //     videoRef.current.currentTime = delay.toFixed(4);
    //   }
    //   requestAnimationFrame(seekVideo);
    // };
    // requestAnimationFrame(seekVideo);

    const canvas = videoRef.current;
    const context = canvas.getContext("2d");

    const frameCount = 122;
    // const currentFrame = (index) => {
    //   const filePath = `/videoImages/ezgif-frame-/${index
    //     .toString()
    //     .padStart(3, "0")}.jpg`;
    //   return filePath;
    // };
    const images = [null];
    const preloadImages = () => {
      for (let i = 1; i < frameCount; i++) {
        if (i % 10 === 0) {
          images[i] = new Image();
          images[
            i
          ].src = require(`../../assets/videos/videoImages/ezgif-frame-${i
            .toString()
            .padStart(3, "0")}.jpg`);
        }
      }
      for (let i = 1; i < frameCount; i++) {
        if (i % 10 !== 0) {
          images[i] = new Image();
          images[
            i
          ].src = require(`../../assets/videos/videoImages/ezgif-frame-${i
            .toString()
            .padStart(3, "0")}.jpg`);
        }
      }
    };

    const img = new Image();
    // img.src = require(`../../assets/videos/videoImages/ezgif-frame-001.jpg`);
    // canvas.width = scrollContentVidRef.current.getBoundingClientRect().width;
    // canvas.height = scrollContentVidRef.current.getBoundingClientRect().height;
    canvas.width = 500;
    canvas.height = 500;
    img.onload = function () {
      context.drawImage(images[1], 0, 0, 500, 500);
    };

    const updateImage = (index) => {
      // img.src = require(`../../assets/videos/videoImages/ezgif-frame-${index
      //   .toString()
      //   .padStart(3, "0")}.jpg`);
      context.drawImage(images[index], 0, 0, 500, 500);
      // setCurrentFrame(index.toString().padStart(3, "0"));
    };

    window.addEventListener("scroll", () => {
      const scrollTop =
        window.innerHeight -
        boundRef.current.getBoundingClientRect().top -
        window.innerHeight / 2;
      const maxScrollTop = boundRef.current.getBoundingClientRect().height;
      const scrollFraction = Math.max(0, scrollTop / maxScrollTop);
      const frameIndex = Math.min(
        frameCount - 1,
        Math.ceil(scrollFraction * frameCount)
      );

      // console.log("scroll", scrollTop, scrollFraction, frameIndex);

      requestAnimationFrame(() => updateImage(frameIndex + 1));
    });
    if (window.innerWidth > 768) {
      preloadImages();
    }

    // new Image().src = require("../../assets/videos/videoImages/ezgif-frame-003.jpg");
  }, []);

  return (
    <>
      <Header />

      <div className={styles.containerBody}>
        <div className={styles.titleVideo}>
          <div className={styles.landingTitle} ref={landingTitleRef}>
            <div className={styles.animateBottomContainer}>
              <div className={styles.animateBottom}>buidl</div>
            </div>
            <div className={styles.animateBottomContainer}>
              <div
                className={`${styles.animateBottom} ${styles.animateBottomLine2}`}
              >
                on-chain
              </div>
            </div>
            <div className={styles.animateBottomContainer}>
              <div
                className={`${styles.animateBottom} ${styles.animateBottomLine3}`}
              >
                reputation
              </div>
            </div>
          </div>
          <div style={{ background: "black" }} ref={chainVideoRef}>
            <video
              autoPlay
              loop
              muted
              className={styles.landingVideo}
              preload="true"
            >
              <source src={LandingAnimation}></source>
            </video>
          </div>
        </div>
        <div className={styles.BodyAfterVideo}>
          {/* <div
            dangerouslySetInnerHTML={{
              __html: `<html><head><style>.red{ background-color: red;}</style></head><body><div class="red">This is a html set</div><script>console.log("from script")</script></body></html>`,
            }}
          /> */}
          {/* <div
            dangerouslySetInnerHTML={{
              __html: htmlContent,
            }}
          /> */}
          <div className={styles.mission}>
            <div>
              <div className={styles.missionTitle}>MISSION</div>
              <div className={styles.missionLine} ref={missionLineRef}></div>
              <div className={styles.missionText}>
                Drepute enables contributors to build their reputation and
                manage identity across Web3
              </div>
            </div>
          </div>
          <div className={styles.scrollContainer}>
            {/* <div className={styles.scrollBorder}></div> */}
            <div className={styles.scrollItems}>
              <div className={styles.scrollBoundVid} ref={scrollBoundVidRef}>
                <div
                  className={styles.scrollContentVid}
                  ref={scrollContentVidRef}
                >
                  {/* <video muted ref={videoRef} style={{ borderRadius: "4%" }}>
                    <source src={dreputeLandingVideo} type="video/mp4" />
                    <p>
                      Your user agent does not support the HTML5 Video element.
                    </p>
                  </video> */}
                  <canvas ref={videoRef}></canvas>
                  {/* <img
                    ref={videoRef}
                    src={require(`../../assets/videos/videoImages/ezgif-frame-${currentFrame}.jpg`)}
                    alt=""
                  /> */}
                </div>
              </div>
              <div className={styles.scrollTextContainer} ref={boundRef}>
                <div
                  className={styles.scrollTextCover}
                  ref={scrollTextRefContainer1}
                >
                  <div className={styles.scrollText} ref={scrollTextRef1}>
                    <div className={styles.scrollTextImage}>
                      <img src={video1} alt="" />
                    </div>
                    <div className={styles.scrollTextGray1}>
                      Get acknowledged and earn badges
                    </div>
                    <div className={styles.scrollTextWhite1}>
                      Get on-chain badges with payments for contributions,
                      verified by community.
                    </div>
                  </div>
                </div>
                {/* <div className={styles.scroller} ref={scrollerRef1}></div> */}
                <div
                  className={styles.scrollTextCover}
                  ref={scrollTextRefContainer2}
                >
                  <div className={styles.scrollText2} ref={scrollTextRef2}>
                    <div className={styles.scrollTextImage}>
                      <img src={video2} alt="" />
                    </div>
                    <div className={styles.scrollTextGrayContainer}>
                      <div className={styles.scrollTextGray}>
                        One profile for everything
                      </div>
                    </div>
                    <div className={styles.scrollTextWhiteContainer}>
                      <div className={styles.scrollTextWhite1}>
                        NFTs, POAPs, governance participation and all your on
                        chain footprint in one place with all your web2 links.
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={styles.scrollTextCover}
                  ref={scrollTextRefContainer3}
                >
                  <div className={styles.scrollText3} ref={scrollTextRef3}>
                    <div className={styles.scrollTextImage}>
                      <img src={video3} alt="" />
                    </div>
                    <div className={styles.scrollTextGrayContainer}>
                      <div className={styles.scrollTextGray}>
                        Accumulate badges, unlock possibilities
                      </div>
                    </div>
                    <div className={styles.scrollTextWhiteContainer}>
                      <div className={styles.scrollTextWhite1}>
                        Gated communities, early access, shareable profile and a
                        lot more
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={styles.scrollTextCover}
                  ref={scrollTextRefContainer4}
                >
                  <div className={styles.scrollText4} ref={scrollTextRef4}>
                    <div className={styles.scrollTextGrayContainer}>
                      <div className={styles.scrollTextImage}>
                        <img src={video4} alt="" />
                      </div>
                      <div className={styles.scrollTextGray}>
                        Manage and control your identity{" "}
                      </div>
                    </div>
                    <div className={styles.scrollTextWhiteContainer}>
                      <div className={styles.scrollTextWhite2}>
                        Manage your secure and private Digital Identity with
                        specific credentials powered by DIDs
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <div>REPUTSTION</div>
            <div> Work for everyone</div>
            <div></div>
          </div> */}
          <div className={styles.whiteChains} ref={whiteChainWrappperRef}>
            <div className={styles.blackText}>
              <div>Start building reputation today</div>
              <div className={styles.blackTextSecond}>
                join some 50+ DAOs using out tool
              </div>
            </div>
            <div className={styles.soonButton}> Coming Soon</div>
            <img
              src={WhiteChains}
              alt="white chains"
              className={styles.whiteChainsImg}
              ref={chainImageRef}
            />
          </div>
          <div className={styles.belowChains}>
            <div className={styles.belowChainsCircle}>
              <img src={Circle} alt="Circle Image" />
            </div>
            <div className={styles.belowChainsText}>
              Start managing contribution in your DAO and <br /> incentevizing
              your community. Appy for closed beta
            </div>
          </div>
        </div>
        <div className={styles.whitelistFormContainer}>
          {/* <div className={styles.skeletonHandVid}> */}

          {/* </div> */}
          <div className={styles.marqueeDiv}>
            <div className={styles.marqueeContentFirst}>
              lets fuckin go<span>/</span>
              lets fuckin go<span>/</span>
              lets fuckin go<span>/</span>
            </div>

            <div className={styles.marqueeContentSecond}>
              lets fuckin go<span>/</span>
              lets fuckin go<span>/</span>
              lets fuckin go<span>/</span>
            </div>
          </div>
          <div className={styles.skeletonHandContainer}>
            {/* <img
              src={SkeletonHand}
              alt="skeleton hand"
              className={styles.skeletonHand}
            /> */}
            {/* <div className={styles.skeletonHandVid}> */}
            <video
              className={styles.skeletonHand}
              autoPlay
              loop
              muted
              preload="true"
              ref={handRotationRef}
            >
              <source src={HandRotation}></source>
            </video>
            {/* </div> */}
            <div className={styles.whitelistForm}>
              <div className={styles.whitelistFormWrapper}>
                <div className={styles.darkHeading}>Coming soon</div>
                <div className={styles.lightHeading}>apply for whitelist</div>
                <input
                  type="text"
                  value={walletAddress}
                  className={styles.formInput}
                  placeholder="Email address(optional)"
                  onChange={(e) => setWalletAddress(e.target.value)}
                />
                <input
                  type="text"
                  value={email}
                  className={styles.formInput}
                  placeholder="Wallet address / ENS"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className={styles.submitBtn}>Submit</button>
              </div>
              <div className={styles.bottomFilter}>
                <div className={styles.footerText}>come hang out with us</div>
                <div className={styles.footerTextBottom}>
                  check all updates on
                  <a href="https://twitter.com/dreputexyz" target="_blank">
                    twitter
                  </a>
                </div>
                <div className={styles.footerSocialIcons}>
                  <a href="https://twitter.com/dreputexyz" target="_blank">
                    <img src={twitterIcon} alt="twitter" />
                  </a>
                  <a href="https://discord.gg/xxdCpXmpGG" target="_blank">
                    <img src={discordIcon} alt="discord" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
