import React, { useState, useRef } from "react";
import styles from "./style.module.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import CodeEditor from "../../component/CodeEditor/index";

export default function FormPage() {
  const [name, setName] = useState("");
  const [tagline, setTagline] = useState("");
  const [username, setUserName] = useState("");
  const [launchDate, setLaunchDate] = useState(new Date());
  // const [about, setAbout] = useState("");
  const [pfp, setPfp] = useState({
    image: "",
    url: "",
  });
  const [banner, setBanner] = useState({
    image: "",
    url: "",
  });
  const [howToJoin, setHowToJoin] = useState("");
  const [twitter, setTwitter] = useState("");
  const [discord, setDiscord] = useState("");
  const [website, setWebsite] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [daoTypes, setDaoTypes] = useState(null);

  const selectOptions = [
    {
      value: 0,
      label: "Service",
    },
    {
      value: 1,
      label: "Grant",
    },
    {
      value: 2,
      label: "Media",
    },
    {
      value: 3,
      label: "Social",
    },
    {
      value: 4,
      label: "Investment",
    },
    {
      value: 5,
      label: "Protocol",
    },
    {
      value: 6,
      label: "Creator",
    },
    {
      value: 7,
      label: "Collector",
    },
    {
      value: 8,
      label: "Gaming",
    },
  ];

  const MAX_IMAGE_SIZE = 1000000;

  const S3_API_ENDPOINT =
    "https://qs17tofrz7.execute-api.us-east-1.amazonaws.com/uploads";

  const createImage = (file, key) => {
    // var image = new Image()
    let reader = new FileReader();
    reader.onload = (e) => {
      if (e.target.result.length > MAX_IMAGE_SIZE) {
        return alert("Image is loo large.");
      }
      const imageObj = {
        url: "",
        image: e.target.result,
      };
      if (key === "pfp") {
        setPfp({ ...imageObj });
      } else {
        setBanner({ ...imageObj });
      }
    };
    reader.readAsDataURL(file);
  };

  const onFileChange = (e, key) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    createImage(files[0], key);
  };

  const uploadImage = async (e, key) => {
    const response = await axios({
      method: "GET",
      url: S3_API_ENDPOINT,
    });
    let binary;
    if (key === "pfp") {
      binary = atob(pfp.image.split(",")[1]);
    } else {
      binary = atob(banner.image.split(",")[1]);
    }
    let array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    let blobData = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
    const result = await fetch(response.data.uploadURL, {
      method: "PUT",
      body: blobData,
    });
    // Final URL for the user doesn't need the query string params
    if (key === "pfp") {
      setPfp((pfp) => ({ ...pfp, url: response.data.uploadURL.split("?")[0] }));
    } else {
      setBanner((banner) => ({
        ...banner,
        url: response.data.uploadURL.split("?")[0],
      }));
    }
  };

  const removeImage = (e, key) => {
    if (key === "pfp") {
      setPfp({ url: "", image: "" });
    } else {
      setBanner({ url: "", image: "" });
    }
  };

  const sendForm = async () => {
   let about =  childRef.current.getText()
    if (!name) {
      alert("Please enter a name");
      return;
    } else if (!username) {
      alert("Please enter a username");
      return;
    } else if (!tagline) {
      alert("Please enter a tagline");
      return;
    } else if (
      !about ||
      !howToJoin ||
      !twitter ||
      !website ||
      !discord ||
      !pfp.url ||
      !banner.url
    ) {
      alert("Please fill all the fields");
      return;
    }
    const formData = {
      name,
      user_name: username,
      tag_line: tagline,
      launch_date: launchDate.toISOString(),
      about,
      how_to_join: howToJoin,
      twitter_id: twitter,
      discord_server: discord,
      website,
      pfp: pfp.url,
      banner: banner.url,
      dao_types: daoTypes.map((type) => type.label.toUpperCase()),
    };
    const response = await axios.post(
      "https://staging.api.drepute.xyz:8000/dao/r3pute",
      formData
    );
    if (response.status === 200) {
      setFormSubmitted(true);
      setName("");
      setUserName("");
      setTagline("");
      about = ""
      setBanner({
        image: "",
        url: "",
      });
      setPfp({
        image: "",
        url: "",
      });
      setHowToJoin("");
      setTwitter("");
      setDiscord("");
      setWebsite("");
    }
  };


  const childRef = useRef();

  return (
    <div className={styles.FormPage}>
      {formSubmitted ? (
        <div>
          <h1>Form Submitted Successfully</h1>
          <button onClick={() => setFormSubmitted(false)}>Fill Another</button>
        </div>
      ) : (
        <>
          <h1>DAO Discovery Form</h1>
          <div className={styles.formInputContainer}>
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter name"
            />
          </div>
          <div className={styles.formInputContainer}>
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Enter Username"
            />
          </div>

          <div className={styles.formInputContainer}>
            <label>Tagline</label>
            <input
              type="text"
              value={tagline}
              onChange={(e) => setTagline(e.target.value)}
              placeholder="Enter tagline"
            />
          </div>
          <div className={styles.formInputContainer}>
            <label>Type</label>
            <Select
              defaultValue={daoTypes}
              onChange={setDaoTypes}
              options={selectOptions}
              isMulti
            />
          </div>

          <div className={styles.formInputContainer}>
            <label>LaunchDate</label>
            <DatePicker
              selected={launchDate}
              onChange={(date) => setLaunchDate(date)}
            />
          </div>

          <div className={styles.formInputContainer}>
            <label>About</label>
            <div  className={styles.tiptapContainer}>
              <CodeEditor
                ref={childRef}
              />
            </div>
          </div>

          <div className={styles.formInputContainer}>
            <label>Pfp</label>
            <input
              className={styles.fileInput}
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={(e) => onFileChange(e, "pfp")}
            />
            {pfp.image && !pfp.url && (
              <div>
                <img src={pfp.image} alt="" />
                <button onClick={(e) => removeImage(e, "pfp")}>
                  Remove Image
                </button>
                <button onClick={(e) => uploadImage(e, "pfp")}>
                  Upload Image
                </button>
              </div>
            )}
          </div>

          <div className={styles.formInputContainer}>
            <label>Banner</label>
            <input
              className={styles.fileInput}
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={(e) => onFileChange(e, "banner")}
            />
            {banner.image && !banner.url && (
              <div>
                <img src={banner.image} alt="" />
                <button onClick={(e) => removeImage(e, "banner")}>
                  Remove Image
                </button>
                <button onClick={(e) => uploadImage(e, "banner")}>
                  Upload Image
                </button>
              </div>
            )}
            {banner.url && <div>Image Uploaded</div>}
          </div>

          <div className={styles.formInputContainer}>
            <label>How to Join</label>
            <textarea
              value={howToJoin}
              onChange={(e) => setHowToJoin(e.target.value)}
              placeholder="Enter how to join"
              rows="5"
            />
          </div>

          <div className={styles.formInputContainer}>
            <label>Twitter</label>
            <input
              type="text"
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
              placeholder="Enter twitter"
            />
          </div>

          <div className={styles.formInputContainer}>
            <label>Discord</label>
            <input
              type="text"
              value={discord}
              onChange={(e) => setDiscord(e.target.value)}
              placeholder="Enter discord"
            />
          </div>

          <div className={styles.formInputContainer}>
            <label>Website</label>
            <input
              type="text"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              placeholder="Enter website"
            />
          </div>

          <div className={styles.formButtons}>
            <button onClick={() => sendForm()}>Send</button>
          </div>
        </>
      )}
    </div>
  );
}
